export default {
  methods: {
    turnOnFallbackMode() {
      // hide apps
      const matchesToHide = document.querySelectorAll(
        '#mz-app, #mz-autocomplete'
      )
      matchesToHide.forEach(function (match) {
        match.style.setProperty('display', 'none', 'important')
      })
      // remove placeholders
      const matchesToRemove = document.querySelectorAll(
        '.mz-loader, .mz-placeholder'
      )
      matchesToRemove.forEach(function (match) {
        match.parentNode.removeChild(match)
      })
      // remove mezereon class from body
      document.querySelector('body').classList.remove('mezereon')
    }
  },
  created() {
    this.$store.dispatch('search/setContext', mz.context, { root: true })
  }
}
