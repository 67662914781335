<template>
  <div>
    <mz-search-box
      v-for="input of matches"
      :key="input.id"
      :input="input"
      :query-param="queryParam"
      :search-url="searchUrl"
      :placement="placement"
      :delay="delay"
      :query-on-hover="queryOnHover"
      @change="onChange"
    ></mz-search-box>
  </div>
</template>

<script>
import MzShopifySearchBox from './MzShopifySearchBox'

export default {
  components: { 'mz-search-box': MzShopifySearchBox },
  props: {
    selector: {
      type: String,
      required: false,
      default: 'input[name="q"]'
    },
    searchUrl: {
      type: String,
      required: true
    },
    queryParam: {
      type: String,
      required: false,
      default: 'q'
    },
    placement: {
      type: String,
      required: false,
      default: undefined
    },
    delay: {
      type: Number,
      required: false,
      default: 0
    },
    queryOnHover: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      matches: []
    }
  },
  mounted() {
    // load suggestions
    const self = this
    setTimeout(() => self.loadAutocomplete(), 200)
  },
  methods: {
    loadAutocomplete() {
      const itemList = document.querySelectorAll(this.selector)
      let inputs = []
      Array.from(itemList).forEach(function (item) {
        inputs.push(item)
      })
      this.matches = inputs
    },
    onChange({ input, value }) {
      input.value = value
    }
  }
}
</script>
